import { Divider, useAuthenticator } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from 'uuid';
import React, { MouseEventHandler } from 'react';
import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import awsconfig from '../aws-exports';
import "@aws-amplify/ui-react/styles.css";
import { getReceipt } from '../graphql/queries'; 
import { createReceipt, deleteReceipt } from '../graphql/mutations';
import { uploadData } from 'aws-amplify/storage';
import { generateClient } from 'aws-amplify/api';
import { useState, useEffect, useRef, useCallback } from 'react';
import { listReceipts } from '../graphql/queries'; // Import the GraphQL query
import { fetchUserAttributes, fetchAuthSession, signOut } from 'aws-amplify/auth';
import { Text, Card, Button, Icon, View } from '@aws-amplify/ui-react';
import { Message, Flex } from '@aws-amplify/ui-react';
import { MarketingPricingHome, FeaturesHome, CTASectionHome, MarketingFooterHome } from "../ui-components";
import { ToastContainer, toast } from 'react-toastify';

import { S3 } from 'aws-sdk';
// import { colors } from "@material-ui/core";


Amplify.configure(awsconfig);


export function MyExpense() {
  const client = generateClient();
  const { user } = useAuthenticator((context) => [context.user]);

  // Define state variables for the file upload count
  const [uploadCount, setUploadCount] = useState(0);

  // const [isFetching, setIsFetching] = useState(false);
  
  // Define state variables for the upload status
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);

  // Define state variables for the get receipt function
  const [receipt, setReceipt] = useState(null);

  // Define state variables for the receipts and the next token
  const [nextToken, setNextToken] = useState(null);

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [processedImageUrl, setProcessedImageUrl] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  // const [isZoomed, setIsZoomed] = useState(false);

  // Define a state variable for the filter
  // const [filter, setFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  // const [filteredCsvUrl, setFilteredCsvUrl] = useState('');

  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [isUploadClicked, setIsUploadClicked] = useState(false);
  

  const [value, setValue] = useState('');

  // for manual record entry
  const [isManualEntryClicked, setIsManualEntryClicked] = useState(false);
  const [name, setName] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [total, setTotal] = useState('');


  //Myexpense constants
  const [receiptsTable, setReceiptsTable] = useState<any[]>([]);
  const [listIsLoading, setListIsLoading] = useState(false);

  
  // // Define a function to handle mouse enter and leave events
  // const handleMouseEnter = () => setIsZoomed(true);
  // const handleMouseLeave = () => setIsZoomed(false);


  

  // Function to print access token and id token
  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      setUserEmail(userAttributes.email ?? null);
      console.log("user:", userAttributes);
    } catch (error) {
      console.log(error);
    }
  }
    

      // Call the function to fetch user attributes
      useEffect(() => {
        handleFetchUserAttributes();
    }, []);

    


  // Assuming `getFile` is a function that retrieves a File object from an input element or another source
  const getFile = (e: React.ChangeEvent<HTMLInputElement>) => e.target.files ? e.target.files[0] : null;

  // Define an event handler for the file input change event
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    // Define a function to get the user session and group
    const session = await fetchAuthSession();
    const userEmail = session.tokens?.idToken?.payload['email'];
    const userGroup: any =  session.tokens?.idToken?.payload['cognito:groups'];
    console.log("userEmail:", userEmail);
    console.log("userGroup:", userGroup);

    // Query the number of uploads for the user using "owner" as the filter from the receipts table
    const uploadCountData: any = await client.graphql({
      query: listReceipts,
      variables: {
        filter: {
          owner: {
            eq: userEmail
          }
        },
      },
      authToken: session.tokens?.idToken?.toString() ?? ''       
    });
    console.log("uploadCountData:", uploadCountData);
    const uploadCount = uploadCountData.data.listReceipts.items.length;
    console.log("User's uploadCount:", uploadCount);


    // If the userGroup list is undefined or does not have "paid_users", set the upload limit to 2 otherwise proceed with any number of uploads
    if (!userGroup || !userGroup.includes("paid_users")) {
      console.log(userEmail + " is not a paid user")
      if (uploadCount >= 10) {
        alert ("You have reached the maximum number of trials. Please upgrade to a paid plan to process more documents.");
        return;
      }
    }

    // Increment the upload count
    setUploadCount(uploadCount + 1);


    // Get the file
    const file = getFile(e);

    // Check if file is not null before uploading
    if (file) {
      // Check the file size (50MB limit)
      const maxSize = 50 * 1024 * 1024; // 50MB in bytes
      if (file.size > maxSize) {
        alert('The selected image must be smaller than 50MB.');
        return; // Exit the function if the file is too large
      }
      // Clear the previous image
      setProcessedImageUrl(null);
      setIsFetching(true);

      setUploadStatus(null);
      setReceipt(null);
      setImageUrl(URL.createObjectURL(file));

      // Create a unique file name:
      const uuid = uuidv4();
      const extension = (file.name).split('.').pop();
      const fileNameWithoutExtension = (file.name).split('.').slice(0, -1).join('.');

    // Sanitize the filename by replacing spaces and special characters with underscores
    const sanitizedFileNameWithoutExtension = fileNameWithoutExtension.replace(/[^a-zA-Z0-9_.-]/g, '_');
    const modifiedFileName = `${sanitizedFileNameWithoutExtension}_${uuid}.${extension}`;

      // const modifiedFileName = `${fileNameWithoutExtension}_${uuid}.${extension}`;

      // const modifiedFileName = uniquefilename(file.name);


      // Define the uploadFile function
      const uploadFile = async (file: File) => {
        // Upload the Storage file:
        const result = await uploadData({ 
          key: modifiedFileName,
          // key: file.name, 
          data: file,
          options: {
            contentType: 'image/*' // contentType is optional
          }
          
        }).result;
        const image = {
          filename: modifiedFileName,
          file: {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: modifiedFileName
          }
        }
        createRecord(image);
      };

      
      // Upload the Storage file:
      const result = await uploadData({ 
        key: modifiedFileName,
        // key: file.name, 
        data: file,
        options: {
          contentType: 'image/*' // contentType is optional
        }
        
      }).result;
      const image = {
        filename: modifiedFileName,
        file: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: modifiedFileName
        }
      }
      createRecord(image);
    }
  }


  // Create a record in the database for the uploaded file with the image filename
  const createRecord = async (image: { filename: string, file: { bucket: string, region: string, key: string } }) => {
    // const delay = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds));
    // Get the current session
    const session = await fetchAuthSession();
    console.log("session:", session);

    // Create the API record:
    const newReceipt:any = client.graphql({
      query: createReceipt,
      // If createReceipt requires variables, pass them in like this:
      variables: { input: image }, 
      authToken: session.tokens?.idToken?.toString() ?? ''       
    });

    // Set the upload status
    setUploadStatus('File uploaded!');
    console.log("createRecord", newReceipt);

    setIsFetching(true); // Start fetching
    console.log("isFetching:", isFetching)

    // Call getJsonResult and wait for it to complete
    getJsonResult(image);  
  }



      
  // Define a function to add a manual entry record for expenses, with format validation
  // If Name is empty, update as N/A, If Invoice/Receipt Date is empty, update as N/A, If Total is empty, update as 0
  // Strip any leading or trailing spaces from the input fields, strip any special characters other than . and integers from the Total field,
  //Prompt the date fields as MM/DD/YYYY format for the user to enter the date
  const createManualEntryRecord = async () => {
    try {
        // Get the current session
        const session = await fetchAuthSession();
        console.log("session:", session);

        // Format validation for the input fields
        const formattedName = name.trim() === '' ? 'N/A' : name.trim();
        // Convert date format from yyyy-mm-dd to dd-mm-yyyy
        const formattedInvoiceDate = invoiceDate.split('-').reverse().join('-');
        // const formattedInvoiceDate = invoiceDate.trim() === '' ? 'N/A' : invoiceDate.trim();
        const formattedTotal = total.trim() === '' ? '0' : total.trim().replace(/[^0-9.]/g, '');

        // Create the API record with variables: "NAME", "INVOICE_RECEIPT_DATE" & "TOTAL"
        const newReceipt: any = await client.graphql({
            query: createReceipt,
            variables: { input: { NAME: formattedName, INVOICE_RECEIPT_DATE: formattedInvoiceDate, TOTAL: formattedTotal } },
            authToken: session.tokens?.idToken?.toString() ?? ''
        });

        console.log("New receipt created:", newReceipt);
        // Clear the form fields after submission
        setName('');
        setInvoiceDate('');
        setTotal('');
        
        setIsManualEntryClicked(false); // Close the modal after submission
        //call list records function to add the manualentry receipt to the list of receipts table
        listRecords();
    } catch (error) {
        console.error('Error creating manual entry record:', error);
    }
}



// Define a function to get the JSON result and CSV result from the S3 bucket 
const s3 = new S3({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? '',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? ''
  }
});

const getJsonResult = async (image: { filename: string, file: { bucket: string, region: string, key: string } }) => {
  const jsonFileName = 'processed/' + image.filename.split('.')[0] + '_complete_receipt_details' + '.json'; // replace image extension with .json
  const csvFileName = 'processed/' + image.filename.split('.')[0] + '_complete_receipt_details' + '.csv'; // replace image extension with .csv

  // get processed image filename from the original image filename
  // get original file's extension
  const file_extension = image.filename.split('.')[1];
  console.log("file_extension:", file_extension);

  // if the file extension is pdf, then the processed image extension will be png
  // else the processed image extension will be the same as the original image extension
  let processedImageFileName;
  if (file_extension === 'pdf') {
    processedImageFileName = 'processed/' + image.filename.split('.')[0] + '_processed' + '.png'; // replace image extension with _processed.png
  } else {
    processedImageFileName = 'processed/' + image.filename.split('.')[0] + '_processed' + '.' + file_extension; // replace image extension with _processed.extension
  }

  // const processedImageFileName = 'processed/' + image.filename.split('.')[0] + '_processed' + '.' + file_extension; // replace image extension with _processed.extension

  console.log("jsonFileName:", jsonFileName);
  console.log("csvFileName:", csvFileName);
  console.log("processedImageFileName:", processedImageFileName);

  const jsonParams = {
    Bucket: image.file.bucket,
    Key: jsonFileName
  };
  console.log("jsonParams:", jsonParams);

  const csvParams = {
    Bucket: image.file.bucket,
    Key: csvFileName
  };
  console.log("csvParams:", csvParams);

  const imageParams = {
    Bucket: image.file.bucket,
    Key: processedImageFileName
  };
  console.log("imageParams:", imageParams);

  let receiptData: any;
  let csvData: any;

  setTimeout(() => {
    setIsFetching(false); // End fetching
    console.log("isFetching:", isFetching);

    s3.getObject(jsonParams, (err, data) => {
      if (err) {
        console.error("Error in getObject:", err);
        // Display a message to the user to scan the document properly again
        alert("Error in processing the document. Please scan the document properly and try again.");
        return;
      }
      receiptData = JSON.parse(data.Body?.toString() ?? '');
      console.log("receiptData:", receiptData);
      setReceipt(receiptData);
    });

    s3.getObject(csvParams, (err, data) => {
      if (err) {
        console.error("Error in getObject:", err);
        return;
      }
      csvData = data.Body?.toString();
      console.log("csvData:", csvData);
      // setDownloadUrl(csvData);
      const csvDownloadFileName = image.filename.split('.')[0] + '.csv';
      console.log("csvDownloadFileName:", csvDownloadFileName);
      csvDownloadLink(csvData, csvDownloadFileName);
    }
    );


    s3.getObject(imageParams, (err, data) => {
      if (err) {
        console.error("Error in getObject:", err);
        return;
      }
      if (data.Body) {
        const processedimageUrl = URL.createObjectURL(new Blob([data.Body as BlobPart], { type: 'image/*' }));
        console.log("processedimageUrl:", processedimageUrl);
        setProcessedImageUrl(processedimageUrl);
      } else {
        console.error("Error: data.Body is undefined");
      }
    }); 
    //call listrecords function after 6 seconds to add the receipt to the list of receipts table
  listRecords();
  }
  , 6000);
}


  // Define a function to generate download link for the csv file
 const csvDownloadLink = async (csvData: any, filename: string) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    setDownloadUrl(url);
  }
  


  // render function to display json result in list
  //format renderValue function to leave a line break after each field
  const renderValue = (value: any) => {
    if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>
              {Object.entries(item).map(([key, value]) => (
                <div key={key}>
                  <strong>{key}:</strong> {renderValue(value)}
                  <br />
                </div>
              ))}
            </li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <ul>
          {Object.entries(value).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {renderValue(value)}
              <br />
              <Divider style={{ margin: '8px', borderColor: 'lightgray'}} />
              <br />
            </li>
          ))}
        </ul>
      );
    } else {
      return value;
    }
  };




  // Define a component to display the receipt data
  const ReceiptDetails = ({ receiptData }: { receiptData: any }) => {
    if (!receiptData) return null;
  
  // returns result in list format
    return (
        <div style={{ display: 'flex', flex: '1', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', overflowY: 'auto'}}>
         <div style={{flexDirection: "row"}}>
         <Message  colorTheme="success">Your document has been successfully processed!</Message>
         </div>

        {/* <View width={{ base: '320px', large: "440px" }} > */}
        {/* <Card style={{ width: "auto", marginTop: "20px", justifyContent: "center", alignItems: "center", overflowY: 'auto', overflowX: "scroll", backgroundColor: "black", color: "white",  boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)", borderRadius: "2px" }}> */}

        <Card style={{ height: "540px", width: "auto", marginTop: "20px", justifyContent: "center", alignItems: "center", overflowY: 'auto', overflowX: "scroll", backgroundColor: "white",  boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)", borderRadius: "2px" }}>
        {renderValue(receiptData)}
        </Card>
        {/* </View> */}
      </div>
    );
  };




// Myexpense functions
    // Function to delete a receipt from the database using GraphQL mutation
    const deleteRecord = async (id: string) => {
      try {
          await client.graphql
          ({
              query: deleteReceipt,
              variables: { input: { id } },
          });
          console.log('Receipt deleted successfully');
          // Call the function to list the receipts after deleting
          listRecords();
      } catch (error) {
          console.log('Error deleting receipt', error);
      }
  }
      
  


  const handleDelete = async (id: string) => {
      const confirmDelete = window.confirm('Are you sure you want to delete this receipt?');
      if (confirmDelete) {
          try {
              await deleteRecord(id);
              setReceiptsTable(receiptsTable.filter(receipt => receipt.id !== id));
              toast.success('Receipt deleted successfully');
          } catch (error) {
              console.error('Error deleting receipt:', error);
              toast.error('Failed to delete receipt');
          }
      } else {
          console.log('Deletion cancelled');
      }
  };



  // Function to list receipts from the database using GraphQL query for the current user
  const listRecords = useCallback(async () => {
      try {
          setListIsLoading(true);

      //setNextToken to update the receipts and next token
      const receiptListData: any = await client.graphql({
          query: listReceipts,
          variables: {
          filter: {
              // filter receipts by owner
              owner: {
                  eq: userEmail,
              },
              // filter receipts by createdAt last 3 months
              createdAt: {
                  beginsWith: new Date().toISOString().substring(0, 7), // filter by current month
                  // between: [new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(), new Date().toISOString()],
              },
          },
          },
      });
      console.log("receipt list:", receiptListData);
      // Sort the receipts by createdAt in descending order
      const sortedReceipts = receiptListData.data.listReceipts.items.sort((a: any, b: any) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      // Set the sorted receipts to the state
      setReceiptsTable(sortedReceipts);
      console.log("sorted receipts:", sortedReceipts);
      setListIsLoading(false);
  } catch (error) {
          console.log(error);
  }
  }, [userEmail]);

  
  // Call the function to list receipts if user is authenticated
  useEffect(() => {
      if (userEmail) {
          listRecords();
      }
      }, [userEmail, listRecords]);


  // Function to render the list as table
  const ReceiptListData = ({ receiptListData }: { receiptListData: any[]}) => {
      // Sort the receipts by createdAt in descending order
      const [sortField, setSortField] = useState('createdAt');
      const [sortOrder, setSortOrder] = useState('desc'); // 'asc' for ascending, 'desc' for descending


      const handleSort = (field: string) => {
          const order = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
          setSortField(field);
          setSortOrder(order);
      };


          const sortedReceipts = receiptListData.sort((a, b) => {
              let aValue = a[sortField];
              let bValue = b[sortField];


              // Handle sorting for different fields
              if (sortField === 'INVOICE_RECEIPT_DATE') {
                  const parseDate = (dateString: string) => {
                      const [day, month, year] = dateString.split('-').map(Number);
                      return new Date(year, month - 1, day); // Create a Date object with dayfirst format
                  };
                  aValue = aValue ? parseDate(aValue) : new Date(0); // Use epoch date for invalid dates
                  bValue = bValue ? parseDate(bValue) : new Date(0);
              } else if (sortField === 'TOTAL') {
                  aValue = parseFloat(aValue);
                  bValue = parseFloat(bValue);
              } else if (sortField === 'NAME') {
                  aValue = aValue.toLowerCase();
                  bValue = bValue.toLowerCase();
              }

              if (aValue < bValue) {
                  return sortOrder === 'asc' ? -1 : 1;
              }
              if (aValue > bValue) {
                  return sortOrder === 'asc' ? 1 : -1;
              }
              return 0;
          });
      
      // Calculate the sum of the total amount
      const totalAmount = sortedReceipts.reduce((sum, receipt) => sum + parseFloat(receipt.TOTAL || 0), 0);

      // Display sort arrows in headers
      const getSortArrow = (field: string) => {
          if (sortField === field) {
              return sortOrder === 'asc' ? '↑' : '↓';
          }
          return '↕'; // Default arrow indicating sortable column
      };


      return (
          <div>
              <View fontSize={{ base: '12px', medium: '16px', large: '20px' }} >
              <h2 style={{ textAlign: 'center', color: '#919098', fontSize: '20px' }}>My Expenses</h2>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ul style={{ width: '90%', maxWidth: '800px', marginTop: '20px', alignContent: "center", listStyleType: 'none', padding: 0 }}>
                      <li style={{ color: '#3B3B40', border: '2px solid lightgray', backgroundColor: '#f2f2f2', padding: '8px', display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ width: '33%', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleSort('NAME')} title="Click to sort">
                              VENDOR {getSortArrow('NAME')}
                          </div>
                          <div style={{ width: '33%', textAlign: 'center', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleSort('INVOICE_RECEIPT_DATE')} title="Click to sort">
                              INVOICE/RECEIPT DATE {getSortArrow('INVOICE_RECEIPT_DATE')}
                          </div>
                          <div style={{ width: '33%', textAlign: 'right', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleSort('TOTAL')} title="Click to sort">
                              TOTAL AMOUNT {getSortArrow('TOTAL')}
                          </div>
                      </li>

                      {sortedReceipts.map((receipt) => (
                          <li key={uuidv4()} style={{ padding: '8px', display: 'flex', justifyContent: 'space-between', borderBottom: '2px solid lightgray' }}>
                              <div style={{ width: '33%' }}>{receipt.NAME}</div>
                              <div style={{ width: '33%', textAlign: 'center' }}>{receipt.INVOICE_RECEIPT_DATE || 'N/A'}</div>
                              <div style={{ width: '33%', textAlign: 'right' }}>{receipt.TOTAL}</div>
                              <button onClick={() => handleDelete(receipt.id)} className="myexpense-table-delete-button">x</button>
                          </li>
                      ))}

                      <li style={{ padding: '8px', display: 'flex', justifyContent: 'space-between', borderTop: '2px solid lightgray', backgroundColor: '#f2f2f2' }}>
                          <div style={{ width: '33%' }}></div>
                          <div style={{ width: '33%', textAlign: 'center', fontWeight: 'bold' }}>Total</div>
                          <div style={{ width: '33%', textAlign: 'right', fontWeight: 'bold' }}>{totalAmount.toFixed(2)}</div>
                      </li>

                  </ul>
              </div>
              </View>
          </div>
      );
      };
      






// const fontStyle = { fontFamily: "Sans-serif" };


  return (

    <div>
      {/* <Card style={{ display: 'flex', flex: '1', overflowY: 'auto'}}> */}
            <main>
            
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', justifyContent: 'flex-start', paddingTop: '90px'}}>
              <Text style={{ fontSize: '14px', color: '#919098', paddingLeft: '20px' }}>Welcome! {userEmail}</Text>
            </div>


              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: "20px", paddingBottom: '20px', paddingTop: '20px'}}>
                <label className='upload-button' onClick={() => setIsUploadClicked(true)}>
                    Upload Receipt
                    <input type="file" onChange={handleFileChange} style={{ display: 'none'}} />
                </label>

                <span style={{ fontSize: '22px', color: '#fb3a46', padding: '0 20px', display: 'flex', alignItems: 'center' }}>/</span>

                <label className='add-expense-button' onClick={() => setIsManualEntryClicked(true)}>Add Expense</label>
              </div>


        {isUploadClicked && (
          <Card style={{ display: 'flex', flex: '1', justifyContent: "center", alignItems: "center", flexDirection: 'row', backgroundColor: '#f2f6f4', overflowY: 'auto' }}>
            <Flex direction={{ base: 'column', large: 'row' }}>
              {processedImageUrl && !isFetching && (
                <Card style={{ display: 'flex', flex: '1', justifyContent: "center", alignItems: "center", boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)", borderRadius: "2px", backgroundColor: 'white', margin: 'auto' }}>
                  {/* <div style={{ position: 'relative', width: 'auto', height: '600px' }}> */}
                    <img
                      src={processedImageUrl}
                      alt="Preview"
                      style={{ width: "auto", height: "600px" }}
                    />
                  {/* </div> */}
                </Card>
                    )}


              {isFetching && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh', flexDirection: 'column' }}>
                  <div className="loader" style={{ marginBottom: '20px'}}></div>
                  <Message  colorTheme="info">Your document is being processed...</Message>
                </div>
              )}

                {!isFetching && (
                  <ReceiptDetails receiptData={receipt} />
                )}

                {!isFetching && downloadUrl && (
                  <label className='download-button'>
                    <a href={downloadUrl} download className='download-link'>
                      Download CSV
                    </a>
                  </label>
                  )}
          </Flex>
        </Card>
      )}



            <div>
                {isManualEntryClicked && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={() => setIsManualEntryClicked(false)}>&times;</span>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Vendor:</label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                {/* // Allow users to input date in the foramt Date/Month/Year only with / as the separator */}
                                <div className="form-group">
                                  <label>Invoice/Receipt Date:</label>
                                  <input
                                        type="date"
                                        value={invoiceDate}
                                        onChange={(e) => setInvoiceDate(e.target.value)}
                                    />
                                </div>

                                {/* // Allow users to input total in the foramat 0.00 only with . as the separator */}
                                <div className="form-group">
                                    <label>Amount:</label>
                                    <input
                                        type="number"
                                        value={total}
                                        onChange={(e) => setTotal(e.target.value)}
                                    />
                                </div>

                            </div>
                            <div className="form-actions">
                                <button className="save-button" onClick={createManualEntryRecord}>Save</button>
                                <button className="cancel-button" onClick={() => setIsManualEntryClicked(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
        </div>


    
        <ReceiptListData receiptListData={receiptsTable} />



      </main>
      </div>
      );
      }

       










